.header-wrap {
  width: 100%;
  height: 80px;
  padding-top: 25px;
  box-sizing: border-box;
  z-index: 999;
  // background-image: url("../images/gradient.png");
  // background-repeat: no-repeat;
  // background-size: cover;
  .header {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    .left-logo {
      height: 95px;
      .logo-img {
        margin: auto 10px;
        margin-top: -10px;
        width: 70%;
        height: auto;
      }
      .logo-label {
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 30px;
      }
    }

    .menuA {
      height: 95px;
      a {
        text-decoration: none;
        color: #eeeeee;
        margin: auto 20px;
        margin-top: 6px;
      }
    }
  }
}
.last {
  height: 0px !important;
}
.fixed {
  height: 80px;
  padding-top: 0;
  transition: 1s;
  .header {
    margin-top: 5px;
    width: 95%;
    padding-left: 5%;
    .left-logo {
      .logo-img {
      }
    }
  }
}

.circle {
  background-color: white;
}

.gradient-background {
  width: 300%;
  height: auto;
  margin-left: 130%;
  z-index: 999;
  margin-top: -13%;
}

.gradient-none {
  display: none;
}

@media only screen and (max-width: 1070px) {
  .menuA a {
    margin: auto 0px;
  }
}

@media only screen and (max-width: 930px) {
  .header-wrap .header {
    width: 90%;
  }
}
