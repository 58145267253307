* {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #fbfbfb;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0px;
  width: 100%;
  background-color: #18181a;
  overflow: hidden;
  overflow-y: auto;
  transition: all 1s;
}

.clear-both {
  clear: both;
}
.pro-bold {
  font-family: "ProximaNovaBold";
  color: #f9f4fe;
}
.pro-thin {
  font-family: "ProximaNovaThin";
  color: #f9f4fe;
}
#homeBack {
  position: absolute;
  top: 0px;
  width: 50%;
  left: 25%;
}
#homeBack.port {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}
.main-wrap {
  width: 100%;
  display: none;
  overflow: hidden;
}
.main-wrap.show {
  display: block;
}
.loading {
  position: absolute;
}
.loading img {
  width: 100%;
}
.header-wrap {
  position: fixed;
  left: 0;
  width: 100%;
  height: 60px;
  padding-top: 15px;
  padding-bottom: 10px;
  z-index: 9;
  transition: background-color 0.8s;
}
.header-wrap.cover {
  background-color: rgba(0, 0, 0, 0.5);
  /* opacity: 0.9; */
}
.header {
  margin-left: calc(50% - 670px);
  width: 1280px;
  /* position: relative; */
}
.header .left-logo {
  width: 210px;
  float: left;
  position: relative;
}
.logo-img {
  position: absolute;
  top: 3px;
  width: 45px;
  height: 42px;
}
.logo-label {
  position: absolute;
  top: 4.5px;
  left: 65px;
  font-family: "ProximaNovaThin";
  font-size: 35px;
  color: #f9f4fe;
}
.header-wrap.port {
  background-color: rgba(17, 15, 18, 0.9);
}
.header-wrap.port .header .logo-img {
  top: 5px;
  width: 33px;
  height: 30px;
}

.header-wrap.port .header .logo-label {
  left: 50px;
  top: 5px;
  font-size: 25px;
}

.menu {
  float: right;
  margin-top: 11px;
  display: none;
  z-index: 9;
}
.menu .menu-item {
  font-size: 1px;
  margin-right: 30px;
}

.menu.menuA {
  display: block;
}
.menuA .menuA-item {
  position: relative;
  float: left;
  font-size: 17px;
  margin-left: 30px;
  cursor: pointer;
  text-decoration: none;
}
.menuA .menuA-item .circle {
  position: absolute;
  bottom: -12px;
  left: calc(50% - 3px);
  background-color: #0072ff;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.menu.menuB {
  display: none;
}
.menuB-content {
  position: absolute;
  top: 0px;
  right: -310px;
  width: 290px;
  height: 200vh;
  padding-left: 30px;
  background-color: #121212;
  transition: all 0.5s;
}
.menuB-content.show {
  right: 0px;
}
.menuB .menu-icon {
  float: right;
  cursor: pointer;
}
.menuB .menu-icon img {
  width: 30px;
}
.menuB .menuB-topbar {
  margin-top: 30px;
  margin-bottom: 20px;
  height: 50px;
}
.menuB .menuB-topbar img {
  width: 30px;
}
.menuB .menuB-topbar .menuB-logo {
  float: left;
}
.menuB .menuB-topbar .menuB-close {
  float: right;
  margin-right: 20px;
}
.menuB .menuB-item {
  color: #f9f4fe;
  font-size: 24px;
  padding-top: 10px;
  padding-left: 60px;
  padding-bottom: 30px;
  text-align: left;
  position: relative;
}
.menuB .menuB-item img {
  position: absolute;
  top: 12px;
  left: 0px;
  width: 28px;
  height: 28px;
}
/* .Main-footer {
  margin-top: 30px;
} */

@media only screen and (max-width: 1280px) {
  .header {
    width: 90%;
    margin-left: 5%;
  }
}
@media only screen and (max-width: 1024px) {
  .header-wrap .menu-item {
    font-size: 17px;
  }
}
@media only screen and (max-width: 750px) {
  .header-wrap {
    padding-top: 20px;
  }
}

@media only screen and (max-height: 500px) {
  .header-wrap {
    padding-top: 20px;
  }
}
