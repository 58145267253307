.tab-technology {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin: auto 0;
  margin-bottom: 60px;
}

.tab-technology .title-tech h1 {
  margin: 0;
  margin-bottom: 50px;
  font-size: 3em;
}

.tech-list {
  width: 60%;
  margin: 0 auto;
  padding: 0;
  flex-direction: row;
  display: flex;
}

.nav-tech {
  min-height: 55px;
  max-height: 55px;
  height: 100%;
  width: 16%;
  margin: 0 auto;
  border: 0;
  padding-top: 15px;
}

.tech-list .nav-tech span {
  margin: 0 auto;
  font-size: 16px;
}

.tech-list .active {
  color: #0a5de9;
  border-color: #0a5de9;
  border-bottom: 2px;
  border-style: solid;
}

.tech-list .active span {
  color: #0a5de9;
  text-align: center;
}

.tech-list li {
  list-style-type: none;
  cursor: pointer;
  text-align: center;
}

.tech-list li:hover {
  color: #0a5de9;
}

.tech-list li span:hover {
  color: #0a5de9;
}

.tech-list a {
  text-decoration: none;
  color: rgb(219, 219, 219);
}

.table-tech {
  display: flex;
  width: 67%;
  margin: 30px auto;
  padding: 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px;
}

#design img {
  margin-left: 15%;
  margin-top: 1%;
  max-width: 70%;
  margin-bottom: 5%;
}

.image img {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.image {
  flex-direction: column;
}

.caption {
  margin: 5px auto;
  font-size: 1em;
}

.tab-technology .notice {
  position: relative;
  top: -110px;
  width: 70%;
  bottom: 0;
  background-color: #121212;
}

.tab-technology .notice::after {
  left: 94.7%;
  top: -0.1px;
  bottom: 0;
  height: 25px;
  border-bottom-color: rgba(128, 128, 128, 0.048);
  transform: rotate(180deg);
  z-index: 999;
}

/*Tab*/

ul {
  background: #121212;
}
.tab-tech {
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  background: #121212;
}

.tab-tech .carouse {
  width: 90%;
  margin: 0 auto;
  max-height: 90px;
}
.tab-tech .carousel-tech .tech-inner {
  height: 90px;
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
}

.tab-tech-item {
  display: none;
  width: 100%;
  height: 60px;
}

.tab-tech .carousel-tech .tech-inner .active {
  height: 60px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tab-tech .carousel-tech .tech-inner .tab-tech-item div {
  width: 30%;
  margin: auto 0;
  margin-top: 15px;
  text-align: center;
  height: 60px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.tab-tech-item div span {
  margin: auto 0;
  text-align: center;
}

.tab-tech-item .active {
  border-bottom: 2px solid #0a5de9;
  color: #0a5de9;
  height: 40px;
}

.tab-tech .carousel-control-prev,
.tab-tech .carousel-control-next {
  cursor: pointer;
  width: 10%;
}

.tab-tech .carousel-control-prev .carousel-control-prev-icon,
.tab-tech .carousel-control-next .carousel-control-next-icon {
  width: 35px;
  height: 35px;
  color: #0a5de9;
  overflow: hidden;
}

@media only screen and (max-width: 1160px) {
  .title-tech .col h1 {
    font-size: 3em;
  }
  .caption {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 1030px) {
  .title-tech .col h1 {
    font-size: 2.8em;
  }
}

@media only screen and (max-width: 1000px) {
  .table-tech {
    width: 90%;
  }
}

@media only screen and (max-width: 970px) {
  .title-tech .col h1 {
    font-size: 2.6em;
  }
  .tab-tech {
    margin-top: 0;
  }
  .table-tech .image {
    min-width: 90px;
  }
  .table-tech img {
    max-width: 60;
  }
  .caption {
    font-size: 1em;
  }
}

@media only screen and (max-width: 840px) {
  .title-tech .col h1 {
    font-size: 2em;
  }
  .tech-list {
    width: 100%;
    display: flex;
    row-gap: 5px;
  }
  .tab-technology {
    height: 50vh;
  }
  .tech-list .nav-tech {
    width: 50%;
  }
  .tech-list .nav-tech .nav-link-tech {
    font-size: 10px;
  }
  .navImgContainer {
    width: 100%;
    height: 360px;
    padding-top: 50px;
  }
  .navImg {
    scale: 1.5;
  }
}

@media only screen and (max-width: 700px) {
  .table-tech {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .table-tech .image {
    min-width: 120px;
    margin: 10px auto;
  }
  .tab-tech .carousel-control-prev .carousel-control-prev-icon,
  .tab-tech .carousel-control-next .carousel-control-next-icon {
    width: 25px;
    height: 25px;
  }
}

@media only screen and (max-width: 500px) {
  .title-tech .col h1 {
    font-size: 1.7em;
  }

  .table-tech .image {
    min-width: 160px;
    margin: 10px auto;
  }

  .tab-tech .carousel-control-prev .carousel-control-prev-icon,
  .tab-tech .carousel-control-next .carousel-control-next-icon {
    width: 15px;
    height: 15px;
  }
}

@media only screen and (max-width: 358px) {
  #design img {
    max-width: 120%;
    margin-right: 20%;
    margin-left: -8%;
  }
}

@media only screen and (max-width: 320px) {
  .table-tech .image {
    min-width: 100px;
    margin: 10px auto;
  }
}
