.subFooterContainer {
  width: 100%;
  height: 50vh;
  background: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subFooterContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 3%;
  padding-left: 3%;
  width: 14%;
  height: 100%;
}

.subFooterContent > p {
  padding-top: 5%;
  align-self: flex-start;
}

.subFooterContent > p > a {
  color: white;
  text-decoration: none;
}

.subFooterContent > p:not(p:first-child) {
  font-size: 14px;
}

.subFooterContent > p:hover {
  color: #0a5de9;
  cursor: pointer;
}

.subFooterHeader {
  padding-bottom: 5%;
  font-weight: 600;
  font-size: 1.5em;
}

@media only screen and (max-width: 876px) {
  .subFooterContainer {
    flex-direction: column;
    height: auto;
  }
  .subFooterContent {
    width: 100%;
    margin-bottom: 20px;
  }
  .subFooterHeader {
    padding-bottom: 10px;
  }
  .subFooterContent > p {
    align-self: center;
  }
}
