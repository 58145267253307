.efficient {
  padding: 0% 5% 8% 3%;
  display: flex;
  justify-content: space-between;
  height: 85vh;
  position: relative;
}

.efficient-left {
  position: relative;
}

.eff-line {
  width: 1px;
  position: absolute;
  height: 70%;
  right: 0;
  background: #fff;
}

.efficient-left,
.efficient-mid,
.efficient-end {
  padding: 5%;
}

.efficient-bg {
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
  position: absolute;
  opacity: 0.2;
}
.left-head {
  padding-bottom: 10%;
  color: #007bff;
}

#efficient-head {
  margin-bottom: 10%;
}

.mid-head {
  padding-bottom: 5%;
  color: #007bff;
}
.mid-mid h1 {
  font-weight: 700;
  font-size: 3em;
}

.left-SubText {
  min-width: 150px;
  padding-left: 20px;
  padding-bottom: 20%;
  font-size: 1em;
}
.partnerLogo {
  max-width: 15%;
  margin-right: 15px;
}

.efficient-vrline {
  width: 1px;
  height: 60%;
  background: white;
  margin-top: 3%;
}

.efficient-mid {
  margin-left: 10%;
}

.mid-subText p {
  font-size: 1em;
  color: grey;
  margin-top: 5%;
  padding-bottom: 5%;
  margin-right: 25%;
}

.efficient-mid button {
  background-color: transparent;
  border: 2px solid white;
  padding: 10px 30px;
}

.efficient-mid button:hover {
  transition: 0.5s;
  background-color: white;
  color: black;
}

.efficient-end {
  margin-top: 4%;
  width: 70%;
}

.end-subText {
  font-size: 1em;
  margin-top: 10px;
  margin-bottom: 20%;
  line-height: 35px;
}

.end-head strong span {
  color: #007bff;
  font-size: 3em;
  transition: 1 ease-in-out;
}

.info {
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
  background-color: #0b0b0b;
}

.info a button {
  color: #007bff;
  background-color: transparent;
  border: 2px solid transparent;
  border-color: #007bff;
  padding: 10px 50px;
}

.info a button:hover {
  transition: 0.5s;
  color: white;
  background-color: #007bff;
}

.notice {
  width: 350px;
  bottom: 0;
  background-color: #0b0b0b;
  display: flex;
  height: 50px;
  position: sticky;
}
.notice::after {
  left: 100%;
  bottom: 0;
  height: 100px;
  border-bottom-color: #0b0b0b;
}

.dropMessage {
  background: #0b0b0b;
  padding: 5% 0 5% 0;
}

@media only screen and (max-width: 950px) {
  .efficient-end {
    text-align: center;
    margin: auto;
  }
  .efficient {
    display: flex;
    flex-flow: column;
    width: fit-content;
    height: fit-content;
  }
  .eff-line {
    display: none;
  }
  .efficient-mid {
    text-align: center;
    margin-left: 3%;
  }
  .left-head {
    padding-bottom: 7%;
    font-size: 5.5vw;
    text-align: center;
  }
  .left-SubText {
    max-width: 50%;
    margin: 30px auto;
    padding: 0 0 0 5%;
    display: flex;
    align-items: center;
    font-size: 3.5vw;
  }
  .partnerLogo {
    margin-right: 40px;
    max-width: 15%;
  }
  .efficient-left {
    margin-bottom: 40px;
  }
  .mid-head {
    margin-bottom: 30px;
  }
  .mid-subText p {
    margin-right: 5%;
    margin-bottom: 40px;
  }

  .info {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 90px;
  }
  .info a button {
    width: 70%;
    font-size: 1.1em;
  }
  .dropMessage {
    background: #0b0b0b;
    padding: 15% 0 20px 0;
  }
}

#efficient_main .info h1 {
  margin-bottom: 2em !important;
}
