.footer-wrap {
  background: #121212;
}

.footer-logo {
  width: 130px;
  height: auto;
  margin-top: 10%;
  margin-left: 40%;
  z-index: 100;
}

.footer-logo2 {
  display: none;
}

.footer-main {
  display: flex;
  justify-content: space-between;
}

.footer-content {
  display: flex;
}

.footer-content {
  position: relative;
  margin-top: 30px;
  float: right;
  height: 70px;
  width: 70%;
  background-color: #1e1d1d;
  display: flex;
  padding: 0% 4%;
}
.footer-content::before {
  position: absolute;
  right: 100%;
  top: 0px;
  border-bottom: 70px solid #1e1d1d;
  border-left: 70px solid transparent;
  content: "";
  display: block;
}

.footer-item {
  padding: 30px;
  font-size: 100%;
  white-space: nowrap;
}

.footer-item > a {
  color: #fff;
}

.footer-item > a:hover {
  color: #0072ff;
  text-decoration: none;
}

.footer-content .footer-item:hover {
  transition: 0.5s;
  color: #0072ff;
  cursor: pointer;
}
.footer-bottom {
  display: none;
}
@media only screen and (max-width: 876px) {
  .footer-wrap {
    display: none;
  }
  .footer-main {
    display: none;
  }
  .footer-text {
    display: none;
  }
  .footer-logo {
    height: 0;
  }
  .footer-item {
    font-size: 15px;
    font-weight: bold;
    padding: 1%;
  }

  .infoTech {
    display: none;
  }
  .footer {
    display: flex;
    flex-flow: column;
    width: fit-content;
    height: fit-content;
  }

  .footer-wrap {
    display: flex;
    flex-flow: row;
    width: fit-content;
    height: fit-content;
    background-color: #121212;
  }

  .footer-content {
    margin-top: 10px;
    height: 70px;
    background-color: #121212;
    display: flex;
    flex-flow: column;
    width: 100vw;
    height: fit-content;
    word-wrap: break-word;
    text-align: center;
  }

  .footer-bottom {
    /* margin-top: 10px; */
    height: 70px;
    background-color: #121212;
    display: flex;
    flex-flow: column;
    width: 100vw;
    height: fit-content;
    word-wrap: break-word;
    text-align: center;
  }

  .footer-text {
    display: none;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    padding-top: 15px;
    font-weight: bold;
  }

  .footer-text:hover {
    transition: 0.5s;
    color: #0072ff;
    cursor: pointer;
  }

  .footer-bottom-copyright {
    font-size: 90%;
    margin: 50px 10px;
  }
}
