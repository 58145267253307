.start {
  margin-top: 15%;
  margin-right: 10%;
  margin-left: 10%;
  text-align: center;
}

#workmain h1 {
  font-size: 3vw;
}

#workmain h1 > strong > span {
  font-size: 3.1vw;
}

#workmain h6 {
  font-size: 1em;
  margin-top: 30px;
  margin-bottom: 10%;
  line-height: 25px;
  margin-left: 7%;
}

#row {
  display: flex;
}

.WorkColumn {
  flex: 33.33%;
  padding: 2%;
}

#Worklast h2 {
  display: block;
  margin-top: 15%;
  font-size: 2.1em;
  font-weight: bold;
}

#Image {
  width: 100%;
  max-width: 100%;
}

#Image :hover {
  background-color: red;
}

.btn {
  transition: 0.5s;
  margin-top: 4%;
  color: #0a5de9;
  padding: 1.5%;
  padding-left: 4%;
  padding-right: 4%;
  font-size: 1em;
  background-color: transparent;
  text-align: center;
  border-radius: 0px;
  border: 2px solid #0a5de9;
}

.btn:hover {
  transition: 0.5s;
  background-color: #0a5de9;
  color: white;
  box-shadow: 0 0 5px #0061d0;
}

@media only screen and (max-width: 1309px) {
  #workmain br {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  #row {
    display: block;
  }
  .WorkColumn {
    width: 100%;
    max-width: 100%;
    margin-top: 15%;
    padding-right: calc(5% + 2px);
  }
  .WorkColumn img {
    object-fit: cover;
  }
  #workmain {
    padding: 10px;
    margin-bottom: 10px;
  }
  #workmain h1 {
    font-size: 3.2vw;
  }

  #workmain h1 > strong > span {
    font-size: 3.3vw;
  }

  #workmain h6 {
    font-size: 0.9em;
    text-align: center;
    margin: -25px auto;
  }
  br {
    display: none;
  }
  .col {
    margin-top: 20px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 803px) {
  #workmain {
    padding-top: 50px;
  }

  #worklast {
    margin-top: 10%;
    margin-bottom: 30px;
  }
  #workmain h1 {
    font-size: 3.6vw;
  }

  #workmain h1 > strong > span {
    font-size: 3.7vw;
  }

  #Worklast h2 {
    margin-top: 10%;
    font-size: 1.5em;
  }
  br {
    display: none;
  }
  .btn {
    margin-top: -10px;
  }
}

@media only screen and (max-width: 673px) {
  #lineBreak {
    display: block;
    margin: 100px;
  }
}

@media only screen and (max-width: 657px) {
  .start {
    margin-left: 7%;
    margin-right: 5%;
  }
  #workmain h1 {
    font-size: 5vw;
  }

  #workmain h1 > strong > span {
    font-size: 5.1vw;
  }

  #workmain h6 {
    font-size: 0.8em;
  }

  #Worklast h2 {
    margin-top: 10%;
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 450px) {
  .start {
    margin-top: 20%;
    margin-left: 7%;
    margin-right: 2%;
  }

  #workmain h6 {
    font-size: 0.7em;
  }

  #Worklast h2 {
    padding-top: 20%;
    font-size: 14px;
  }
}
