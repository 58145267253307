.menu-B {
  position: fixed;
  top: 0;
  z-index: 9;
}
.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
.sidebarText {
  font-size: 1em;
  display: block;
  color: white;
  text-align: center;
  padding-top: 10%;
  text-decoration: none;
}

.sidebarText:hover {
  color: #0077bf;
  text-decoration: none;
}

.sidebar .closebtn {
  background: transparent;
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 40px;
  margin-left: 50px;
  color: aliceblue;
  border: none;
  outline: none;
}

.sidebar .closebtn:hover {
  cursor: pointer;
  color: #0077bf;
}
.openbtn {
  font-size: 20px;
  cursor: pointer;
  color: white;
  padding: 10px 15px;
  border-color: transparent;
  background: transparent;
  outline: none;
  border: none;
}

.openbtn:hover {
  background-color: #0077bf;
}

#main {
  width: 100vw;
  height: 10vh;
  transition: 0.5s;
  padding: 16px;
  top: 0;
  background: #1b1b1b;
}

#mySidebar .sidebarcompany {
  text-align: center;
  margin: 1% 0% 20% 0%;
  font-size: 2em;
}

.closebtn:hover {
  color: #0077bf;
}

.sidebarBar {
  width: 80px;
  height: 5px;
  background-color: #0077bf;
  margin-top: 30px;
  margin-left: 35%;
}
