.offerContainer {
  width: 100%;
  height: 80vh;
  display: flex;
}

.offerIndicatorContainer {
  width: 25%;
  padding: 20px;
  margin-left: 12%;
  height: 100%;
  position: relative;
}

.offerScrollMenuContainer {
  width: 45%;
  margin-left: 3%;
  margin-top: 2%;
  height: 75%;
  overflow-y: scroll;
}

.offerScrollMenuContainer::-webkit-scrollbar {
  display: none;
}

.offerScrollMenuContent {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.offerTabLine {
  width: 1px;
  height: 67%;
  z-index: 5;
  top: 7%;
  left: 27px;
  background: #8d8c8c;
  position: absolute;
}

.offerTabCircle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #8d8c8c;
  background: #131313;
  z-index: 6;
}

.offerTabContainer {
  width: 100%;
  height: 10%;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.offerDescription {
  font-size: 0.85vw;
}

.offerTabText {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-top: 2px;
  color: #989898;
}

.offerScrollMenuContentHeader {
  width: 90%;
  height: 25%;
  display: flex;
  position: relative;
}

.offerScrollMenuContentHeaderText {
  width: 40%;
  font-size: 2vw;
}

.offerScrollMenuContentHeaderLine {
  width: 35%;
  height: 2px;
  background: #0a5de9;
  border-radius: 10px;
}

.offerScrollMenuContentHeaderIcon {
  position: absolute;
  top: 0;
  right: 0;
}

.offerScrollMenuContentHeaderIcon > img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.offerScrollMenuContentDescription {
  padding-top: 30px;
  height: 75%;
  position: relative;
}

.offerScrollMenuContentDescription > p {
  color: #989898;
}

.offerScrollMenuContentGrid {
  height: 40%;
  width: 100%;
  padding-top: 20px;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  column-gap: 30%;
}

.offerScrollMenuContentGrid > p {
  width: fit-content;
  color: #989898;
  text-decoration: underline;
  text-underline-offset: 5px;
  font-size: 0.85vw;
}

.offerScrollMenuContentMoreText {
  position: absolute;
  bottom: 0;
}

@media only screen and (max-width: 840px) {
  .offerIndicatorContainer {
    display: none;
  }
  .offerContainer {
    height: auto;
    flex-direction: column;
  }
  .offerScrollMenuContainer {
    width: 100%;
    height: auto;
    margin-top: 50px;
    padding: 0px 30px 0px 10px;
  }
  .offerScrollMenuContent {
    height: auto;
    margin-bottom: 50px;
  }
  .offerScrollMenuContentHeaderText {
    font-size: 25px;
  }
  .offerScrollMenuContentMoreText {
    position: relative;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .offerDescription {
    font-size: 16px;
  }
  .offerScrollMenuContentGrid {
    column-gap: 30px;
  }
  .offerScrollMenuContentGrid > p {
    font-size: 14px;
  }
}
