.companyPolicyContainer {
  width: 100%;
  height: auto;
  margin-top: 12vh;
  padding: 0 5% 5% 5%;
}

.headerText {
  font-size: 2rem;
  text-align: center;
}

.sectionHeader {
  font-size: 1.3rem;
  margin-top: 5%;
}

.text {
  font-size: 1rem;
  margin-left: 3%;
}

.footerText {
  font-size: 1rem;
  margin-top: 1%;
}

.contactText {
  margin-top: 5%;
  font-size: 1rem;
  font-weight: 600;
}
