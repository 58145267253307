.services {
  width: 100%;
  position: relative;
}

.services .content-wrap {
  background-color: #0b0b0b;
  width: 100%;
  height: 110vh;
  padding-top: 5%;
  padding-right: 2%;
}

.services .content-wrap .services-content {
  text-align: center;
  padding-bottom: 10%;
}

.services .content-wrap .services-content .main-title {
  font-size: 1.97em;
  padding-top: 2%;
}

.services .content-wrap .services-content .main-description h6 {
  line-height: 1.2;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 3%;
  font-size: 1em;
  font-family: "open sans", arial, sans-serif;
}

.services
  .content-wrap
  .services-content
  .panels
  .panel-row
  .panel-item
  .description {
  opacity: 0;
  color: grey;
  text-align: left;
}

.services .content-wrap .services-content .panels .panel-row {
  width: 100%;
  display: inline-block;
  margin-left: 2%;
}
.services .content-wrap .services-content .panels .panel-row .panel-item {
  margin-top: 0.5%;
  height: 200px;
  text-align: left;
  font-size: 1.2em;
  background-color: #1a1a1a;
  padding-left: 25px;
  width: 23%;
  float: left;
  cursor: pointer;
  margin-left: 1%;
  padding-right: 1%;
  position: relative;
}

.services .content-wrap .services-content .panels .panel-row .panel-item img {
  width: 50px;
  height: 50px;
  margin-top: 10%;
}

.services .content-wrap .services-content .panels .panel-row .panel-title {
  position: absolute;
  bottom: 12%;
}

.services
  .content-wrap
  .services-content
  .panels
  .panel-row
  .panel-title::before {
  content: "";
  position: absolute;
  border: 1px solid #0061d0;
  background-color: #0061d0;
  height: 40px;
  left: -24px;
}

.services .content-wrap .services-content .panels .panel-item:hover {
  background-color: #0061d0;
  transition: 1s;
  border-color: whitesmoke;
  box-shadow: 0 0 7px #0061d0;
}

.services
  .content-wrap
  .services-content
  .panels
  .panel-item:hover
  .panel-title {
  transition: 1s;
  bottom: 36%;
  line-height: 20px;
}

.services
  .content-wrap
  .services-content
  .panels
  .panel-row
  .panel-item:hover
  .description {
  opacity: 1;
  font-size: 0.6em;
  padding-top: 0%;
  text-align: left;
  color: white;
  margin-top: 25%;
}

#product-img {
  width: 60px;
  height: 60px;
}

#nextgen-img {
  width: 60px;
  height: 60px;
}

.panel-item-mobile {
  display: none;
}

@media screen and (max-width: 991px) and (min-width: 530px) {
  .services .content-wrap {
    height: auto;
  }
  .services .content-wrap .services-content .panels .panel-row {
    margin-top: 40px;
    padding-left: 5%;
  }
  .services .content-wrap .services-content .panels .panel-row .panel-item {
    width: 45%;
  }

  .services .notice {
    width: 300px;
  }

  .services .content-wrap .services-content .main-description {
    margin-top: 30px;
    font-size: 16px;
  }
  .services .content-wrap .services-content .panels .panel-row .panel-title {
    padding-top: 5%;
  }
  .services .content-wrap .services-content .panels .panel-row {
    margin-top: -7px;
  }
  .services
    .content-wrap
    .services-content
    .panels
    .panel-item:hover
    .description {
    padding-top: 1%;
    margin-bottom: 5%;
    text-align: left;
  }
}

@media screen and (max-width: 540px) {
  .services .content-wrap {
    height: auto;
  }
  .services .content-wrap .services-content .panels .panel-row .panel-item {
    width: 80%;
    margin-bottom: 5%;
    height: 220px;
    background-color: #1a1a1a;
    margin-left: 8%;
  }

  .services .content-wrap .services-content .main-description h6 {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 5%;
    font-size: 14px;
    font-family: "open sans", arial, sans-serif;
  }

  .services
    .content-wrap
    .services-content
    .panels
    .panel-row
    .panel-item
    .description {
    font-size: 0.6em;
    padding-top: 0%;
    text-align: left;
  }

  .services .content-wrap .services-content .panels .panel-item:hover {
    background-color: none;
    transition: none;
    border-color: none;
    box-shadow: none;
    text-transform: none;
  }
  .services .content-wrap .services-content .panels .panel-item .panel-title {
    transition: 1s;
    bottom: 30%;
  }
  .services .content-wrap .services-content .panels .panel-item .panel-title {
    transition: 1s;
    bottom: 30%;
  }
  .services
    .content-wrap
    .services-content
    .panels
    .panel-row
    .panel-item
    .description {
    opacity: 1;
    color: grey;
    font-size: 0.6em;
    padding-top: 0%;
    text-align: left;
    margin-top: 25%;
  }

  .services
    .content-wrap
    .services-content
    .panels
    .panel-item:hover
    .description {
    color: grey;
    text-align: left;
    font-size: 0.6em;
  }
  .panel-item {
    display: none;
  }
  .panel-item-mobile {
    display: block;
    width: 100%;
    padding-top: 5%;
    height: auto;
  }
  .panel-row-mobile {
    width: 100%;
    height: 130px;
    background: #1a1a1a;
    padding-left: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    position: relative;
  }

  .panel-row-mobile.active {
    background: #0061d0;
    align-items: start;
    padding-top: 20px;
    padding-right: 20px;
    height: auto;
    flex-direction: column;
    padding-left: 30px;
    position: relative;
    transition: 0.4s ease-in-out;
  }

  .panel-row-mobile.active > .panel-img-mobile {
    width: 50px;
    height: 50px;
    transition: 0.4s ease-in-out;
    position: absolute;
  }

  .panel-row-mobile.active > .panel-line {
    transition: 0.4s ease-in-out;
    display: none;
  }

  .panel-line {
    width: 5px;
    left: 0;
    position: absolute;
    height: 100%;
    background: #0061d0;
  }

  .panel-img-mobile {
    width: 60px;
    height: 60px;
  }
  .panel-row-mobile > p {
    padding-left: 20px;
    padding-top: 25px;
    align-self: center;
    text-align: left;
    font-size: 1.2em;
  }
  .panel-row-mobile.active > p {
    align-self: flex-start;
    margin-top: -20px;
    transition: 0.4s ease-in-out;
    margin-left: 50px;
  }

  .description-mobile {
    display: none;
  }

  .panel-row-mobile.active > .description-mobile {
    display: block;
    margin-top: -20px;
    transition: 0.4s ease-in-out;
    text-align: left;
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .services .content-wrap {
    height: auto;
  }
  .services .content-wrap .services-content .panels .panel-row .panel-item {
    width: 80%;
    margin-bottom: 5%;
    height: 220px;
    background-color: #1a1a1a;
    margin-left: 8%;
  }

  .services .content-wrap .services-content .main-description h6 {
    margin-top: 20px;
    text-align: center;
    transition: 1s;
    margin-bottom: 5%;
    font-size: 14px;
    font-family: "open sans", arial, sans-serif;
  }

  .services
    .content-wrap
    .services-content
    .panels
    .panel-row
    .panel-item
    .description {
    opacity: 1;
    color: grey;
    font-size: 0.55em;
    padding-top: 0%;
    text-align: left;
    margin-top: 35%;
  }

  .services .content-wrap .services-content .panels .panel-item .panel-title {
    transition: 1s;
    bottom: 35%;
  }

  .services .content-wrap .services-content .panels .panel-item:hover {
    background-color: none;
    transition: none;
    border-color: none;
    box-shadow: none;
    text-transform: none;
  }

  .services
    .content-wrap
    .services-content
    .panels
    .panel-item:hover
    .panel-title {
    padding-bottom: 15px;
  }

  .services
    .content-wrap
    .services-content
    .panels
    .panel-item:hover
    .description {
    opacity: 1;
    color: grey;
    font-size: 0.5em;
    padding-top: 10%;
    text-align: left;
  }
}
