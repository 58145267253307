.reason-component {
  margin-top: 10%;
  margin-left: 12%;
  margin-right: 18%;
  margin-bottom: 20%;
}

.reason-header {
  padding-left: 5%;
  text-align: center;
  margin-bottom: 10%;
}
.reason-mid1 {
  margin-bottom: 25%;
  width: 100%;
}
.reason-mid1 img {
  max-width: 50%;
  float: left;
  width: 50%;
}

.reason-mid1 .reason1-sub {
  padding-top: 15%;
  margin-left: 30%;
  width: 90%;
  position: relative;
  padding-left: 40%;
  text-align: left;
}
.reason-mid1 .reason1-sub .line {
  width: 70%;
  height: 3px;
  border-radius: 30px;
  top: 20px;
  left: 400px;
  background-color: #0a5de9;
  position: absolute;
}

.reason-mid1 > .reason1-sub > #line3 {
  top: 135px;
  left: calc(70% + 50px);
}

.reason1-sub > div {
  font-size: 1.5em;
  margin-right: 0;
}

.reason1-sub h6 {
  font-size: 1.1em;
  margin-top: 5%;
  color: #808080;
  line-height: 22px;
}

.reason-mid2 {
  margin-top: 20%;
  width: 110%;
  margin-left: 5%;
  padding-bottom: 30%;
}

.reason-mid2 .reason2-sub {
  width: 45%;
  margin-top: 6%;
  position: relative;
  float: left;
}
.reason-mid2 img {
  max-width: 40%;
  margin-right: 1%;
  float: right;
}
.reason2-sub > div {
  font-size: 1.6em;
}

.reason2-sub h6 {
  font-size: 1.1em;
  margin-top: 8%;
  color: #808080;
  line-height: 22px;
}

.reason-mid2 .reason2-sub > div > .line2 {
  width: 50%;
  height: 3px;
  border-radius: 30px;
  top: 50%;
  left: -60%;
  background-color: #0a5de9;
  position: absolute;
}

.reason-last {
  margin-top: 7%;
}

.reason-last .reason-mid1 .reason1-sub {
  padding-top: 20%;
}

@media screen and (max-width: 990px) {
  .reason-component {
    margin: 0;
    width: 100%;
    height: auto;
  }
  .reason-mid1 {
    width: 100%;
  }
  .reason-mid1 img {
    margin-left: 25%;
    float: none;
  }
  .reason-mid1 .reason1-sub {
    width: 80%;
    padding: 0;
    margin: auto;
    text-align: center;
  }
  .reason-mid1 .reason1-sub .line {
    left: calc(50% + 200px);
  }
  .reason-mid2 {
    width: 100%;
    padding-bottom: 5%;
    margin-top: -8%;
  }
  .reason-mid2 .reason2-sub {
    width: 80%;
    padding: 0;
    margin-left: 5%;
    text-align: center;
    padding-bottom: 5%;
  }
  .reason-mid2 img {
    margin-left: 20%;
    margin-bottom: 3%;
    float: none;
    max-width: 50%;
  }

  .reason-last .reason-mid1 .reason1-sub {
    padding-top: 10%;
    margin-top: -5%;
  }
  .reason-mid2 .reason2-sub > div > .line2 {
    top: 20px;
    left: calc(-45% + -30px);
  }
  .reason-header h1 {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 565px) {
  .reason-mid2 .reason2-sub > div > .line2 {
    top: 20px;
    left: calc(-40% + -20px);
  }
  .reason-mid2 .reason2-sub div {
    padding: 0 10% 0 10%;
  }
}

@media screen and (max-width: 461px) {
  .reason-mid2 .reason2-sub div {
    padding: 0;
  }
  .reason-mid2 .reason2-sub > div > .line2 {
    top: 20px;
    left: calc(-50% + -20px);
  }
}

@media screen and (max-width: 368px) {
  .reason-mid2 .reason2-sub > div > .line2 {
    top: 20px;
    left: calc(-30% + -20px);
  }
}
