.teamSlide {
  display: none;
}

.main {
  margin-bottom: 20px;
}
.yolo {
  width: 100%;
  background-color: #0b0b0b;
  position: relative;
  padding-left: 3%;
  height: 93vh;
  min-height: 100px;
}
.teamSlide {
  background-color: #0b0b0b;
}

.yolo-content {
  position: relative;
}

.yolo-content .yolo-part {
  float: left;
}
.yolo-content .left-taxi {
  width: 25%;
  margin-top: 160px;
  display: flex;
  justify-content: space-between;
}
.yolo-content .left-taxi .notice-blue {
  color: #0061d0;
  font-size: 20px;
}
.yolo-content .left-taxi .yolo-title {
  margin-top: 10px;
  font-size: 2.5em;
}
.yolo-content .left-taxi .description {
  font-size: 1em;
  color: #666666;
  margin-top: 50px;
  width: 100%;
  height: 50px;
}

.yolo-line {
  border: 1px solid white;
  height: 400px;
  margin-top: 10%;
  float: right;
}
.arrow-part {
  display: flex;
}

.teamSlide {
  position: relative;
}

.appShowCase {
  width: 30%;
  height: 30%;
  top: 33%;
  right: 0;
  padding-left: 20px;
  position: absolute;
}

.appContent {
  padding-left: 10px;
  cursor: pointer;
  height: 30px;
  margin-bottom: 30px;
}

.appContent.active {
  border-left: 3px solid #0a5de9;
}

.sliderArrow {
  position: absolute;
  width: 5%;
  top: 80%;
  left: 14%;
  padding: 0 2% 0 2%;
  z-index: 4;
  display: flex;
  justify-content: space-between;
}

.sliderArrows {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.arrow-part .arrows {
  padding-right: 5%;
  font-size: 200%;
  font-weight: bolder;
}

/* .arrow-part img{
  display: initial;
  max-width: 30px;
} */
.arrow-part:hover {
  cursor: pointer;
}

.mobile-arrow-part {
  display: none;
}

.yolo-content .center-img {
  width: 45%;
  height: 100px;
  position: relative;
}
.yolo-content .center-img .yolo-cube-img {
  position: absolute;
  width: 100%;
}
.yolo-content .center-img .yolo-phone-img {
  position: absolute;
  left: 30%;
  width: 50%;
  filter: grayscale(100%);
  opacity: 0.3;
  transition: all 1s;
}
.yolo-content .center-img:hover .yolo-phone-img {
  filter: grayscale(0%);
  opacity: 1;
}
.yolo-content .right-app {
  margin-top: 16%;
  margin-left: 10%;
}
.yolo-content .right-app .app-title {
  font-size: 20px;
}
.yolo-content .right-app .app-content {
  margin-top: 50px;
}
.yolo-content .right-app .app-content-item {
  padding-left: 45px;
  margin-top: 15px;
  font-size: 20px;
  color: #666666;
  font-weight: bold;
  cursor: pointer;
}
.play_image {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  justify-content: space-between;
}

.yolo-content .right-app .app-content-item.active {
  border-left: 2px solid #0061d0;
  color: #dddddd;
}
.yolo-projectsheadline {
  font-size: 55px;
  color: #fbfbfb;
  float: right;
  margin-top: 200px;
  margin-bottom: 40px;
  width: 100%;
  text-align: center;

  font-family: "ProximaNovaRegular";
}

.yolo-projects-subheading {
  font-size: 18px;
  display: block;

  text-align: center;
  color: #707070;
  margin-left: auto;
  margin-right: auto;

  width: 550px;
  margin-top: 40px;

  font-family: "ProximaNovaRegular";
}
.yolo-projects-button {
  font-size: 20px;
  display: block;
  border: 2px solid #095de8;
  text-align: center;
  color: #095de8;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;

  width: 300px;
  margin-top: 40px;

  font-family: "ProximaNovaRegular";
}
.yolo-footer {
  width: 200px;
  background-color: #1b1b1b;
  z-index: 1;
}
.yolo-footer::before {
  right: 100%;
  border-top-color: #1b1b1b;
}

.yolo.port {
  height: calc(100vw + 290px);
}
.yolo.port .yolo-content {
  padding-top: 30px;
}
.yolo.port .yolo-part {
  position: absolute;
  width: 100%;
  margin: 0;
}
.yolo.port .right-app {
  top: 90px;
}
.yolo.port .right-app .app-title {
  text-align: center;
  font-size: 30px;
}
.yolo.port .right-app .app-content {
  margin-top: 20px;
  position: relative;
}
.yolo.port .right-app .app-content-item {
  margin-top: 0px;
  padding-left: 0px;
  position: absolute;
  font-size: 14px;
}
.yolo.port .right-app .app-content .app-content-first {
  left: calc(50% - 150px);
}
.yolo.port .right-app .app-content .app-content-yolo {
  left: calc(50% - 20px);
}
.yolo.port .right-app .app-content .app-content-third {
  right: calc(50% - 150px);
}
.yolo.port .right-app .app-content-item.active {
  border-left: none;
}
.yolo.port .center-img {
  top: 200px;
  height: 100vw;
}
.yolo.port .yolo-content .center-img .yolo-cube-img {
  width: 320px;
  left: calc(50% - 160px);
  top: 20px;
}
.yolo.port .yolo-content .center-img .yolo-phone-img {
  left: calc(50% - 90px);
  width: 180px;
}
.yolo.port .left-taxi {
  top: calc(100vw + 200px);
}
.yolo.port .left-taxi .notice-blue {
  position: absolute;
  top: 50px;
  left: calc(50% - 44px);
  font-size: 14px;
}
.yolo.port .left-taxi .yolo-title {
  text-align: center;
  font-size: 2em;
}
.yolo.port .left-taxi .description {
  text-align: center;
  width: 90%;
  margin-left: 5%;
  margin-top: 20px;
  font-size: 14px;
}
.yolo.port .left-taxi .arrow-part {
  position: relative;
  margin: 0;
}
.yolo.port .left-taxi .arrow-part img {
  position: absolute;
  top: calc(-50vw - 220px);
  margin: 0;
  top: -360px;
}
.yolo.port .left-taxi .arrow-part :nth-child(1) {
  left: calc(50% - 180px);
}
.yolo.port .left-taxi .arrow-part :nth-child(2) {
  right: calc(50% - 180px);
}
.yolo.port .yolo-projectsheadline {
  text-align: center;
}
.yolo.port .yolo-projects-subheading {
  text-align: center;
}
.yolo.port .yolo-projects-button {
  text-align: center;
}
.yolo.port .yolo-footer {
  width: 30vw;
}

.slick-slide img {
  display: initial;
}

.yolo-content .center-img #poultry-img {
  width: 90%;
  left: 0;
}

.yolo-content .center-img #ssb-img {
  width: 90%;
  left: 0;
}

@media only screen and (max-width: 1280px) {
  .yolo {
    padding-bottom: 30px;
  }
  .yolo .left-taxi {
    margin-top: 80px;
  }
  .yolo .left-taxi .description {
    margin-top: 20px;
  }

  .yolo .right-app {
    margin-top: 100px;
  }
  .yolo .right-app .app-content {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .teamSlide {
    display: block;
  }
  .yolo-content .right-app {
    margin-left: 0%;
  }
  .yolo-content .center-img .yolo-phone-img {
    width: 70%;
    left: 35px;
  }
  .yolo-content .left-taxi .description {
    height: 50px;
    font-size: 0.8em;
  }
  .arrow-part img {
    max-width: 25px;
  }
  .arrow-part {
    padding-right: 10px;
  }

  .sliderArrow {
    width: 100%;
    left: 0;
    top: 45%;
    padding: 0 3% 0 3%;
  }
  .yolo-content .center-img #poultry-img {
    width: 100vw;
    left: -25%;
  }
  .yolo-content .center-img #ssb-img {
    width: 100vw;
    left: -25%;
  }
}
@media only screen and (max-width: 540px) {
  .appShowCase {
    display: none;
  }
}

@media only screen and (max-width: 425px) {
  .yolo-content .left-taxi .description {
    display: none;
  }
  .yolo-content .right-app {
    display: none;
  }
  .play_image {
    display: none;
  }
  .yolo .left-taxi {
    text-align: left;
    width: 100%;
    padding-left: 25%;
  }
  .yolo-content .left-taxi .yolo-title {
    margin-top: 0px;
    font-size: 1.2em;
    text-align: center;
  }
  .yolo-content .center-img .yolo-phone-img {
    left: 50%;
    width: 120%;
  }
}

/* @media only screen and (max-width: 600px) {
  .yolo .yolo-content {
    padding-top: 30px;
  }

  .yolo .right-app {
    display: none;
  }
  .yolo .yolo-part {
    float: none;
    width: 100%;
  }
  .yolo .left-taxi .notice-blue {
    text-align: center;
  }
  .yolo .left-taxi .yolo-title {
    text-align: center;
  }

  .yolo-content .center-img .yolo-phone-img {
    padding-right: 10%;
    width: 50%;
  }
}
@media only screen and (max-width: 500px) {
  .yolo {
    height: 750px;
  }
}
@media only screen and (max-width: 400px) {
  .yolo {
    height: 650px;
  }
} */
