.appShowcaseContainer {
  width: 100%;
  height: 100vh;
  background: #0b0b0b;
  display: flex;
  justify-content: space-between;
  /* display: block; */
}

.appShowcaseDescription {
  width: 30%;
  height: 90%;
  padding: 15% 0% 0% 7%;
}

.appShowcaseArrow {
  margin-top: 25%;
}

.appShowcaseCategory {
  color: #0a5de9;
}

.appShowcaseProductName {
  font-size: 2vw;
}

.appShowcaseSubDescription {
  font-size: 16px;
  color: #989898;
}

.appShowcasePlayLinks {
  font-size: 20px;
  width: 80px;
  margin-top: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appShowcaseImage {
  width: 30%;
  height: 90%;
  display: flex;
  justify-content: flex-end;
}

.appShowcaseImage > img {
  width: 90%;
  height: 90%;
  object-fit: cover;
}

.appShowcaseWizard {
  width: 30%;
  height: 90%;
  padding-top: 15%;
  padding-left: 10%;
}

.appShowcaseWizard > p {
  font-size: 1.2vw;
  margin-bottom: 30px;
}

.appShowcaseScrollMenuContainer {
  width: 100%;
}

.appShowcaseScrollMenuContainer::-webkit-scrollbar {
  display: none;
}

.appShowcaseScrollMenu {
  width: 100%;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 35px;
  display: flex;
  align-items: center;
  color: #989898;
  cursor: pointer;
  font-size: 1vw;
}

.appShowcaseScrollMenu.active {
  font-size: 1vw;
  font-weight: 600;
  border-left: 3px solid #0a5de9;
  color: white;
}

.appShowcaseScrollMenu:first-child {
  margin-top: 0;
}

.appShowcaseScrollMenu:last-child {
  margin-bottom: 0;
}

.appShowcaseMobileContainer {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  background: #0b0b0b;
}

.appShowcaseMobileContainer > .mobileHeader {
  font-size: 23px;
  text-align: center;
}

.appshowcaseTabContainer {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
}

.appshowcaseTabContainer::-webkit-scrollbar {
  display: none;
}

.appshowcaseTab {
  display: inline-block;
  height: 85px;
  width: 200px;
  padding: 20px;
  margin-right: 20px;
}

.appshowcaseImageContainer {
  width: 100%;
  height: 50vh;
  position: relative;
}

.appshowcaseImage {
  width: 100%;
  height: 100%;
  margin: 50px auto;
  object-fit: contain;
  scale: 1.2;
}

.mobileArrow {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
}

.mobileArrow:first-child {
  left: 20px;
}

.mobileArrow:last-child {
  right: 20px;
}

.appshowcaseMobileContent {
  width: 100%;
  height: 200px;
  padding: 10px;
  margin: 80px auto;
  text-align: center;
}

.contentHeader {
  font-size: 4vh;
}

.contentDescription {
  font-size: 14px;
  color: #0a5de9;
}

.activeDot {
  width: 8px;
  background: #0a5de9;
  height: 8px;
  margin: 5px auto;
  border-radius: 20px;
}

.appshowcaseMobileContent > p:last-child {
  color: #989898;
}

@media only screen and (max-width: 990px) {
  .appShowcaseImage {
    height: 80vh;
  }
}

@media only screen and (max-width: 768px) {
  .appShowcaseContainer {
    display: none;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .appShowcaseImage > img {
    scale: 1.5;
    object-fit: contain;
  }
}
