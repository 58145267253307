.CompanyHead {
  padding-top: 2rem;
}
.CompanyHeading {
  margin-top: 6%;
  padding-top: 7%;
  padding-left: 12%;
  padding-right: 10%;
  padding-bottom: 10%;
  width: 100%;
  height: 100vh;
}
.CompanyHeading > img {
  position: absolute;
  width: 100%;
  height: 87%;
  top: 13%;
  left: 0;
  z-index: -4;
  opacity: 0.1;
}
.Companyleft {
  float: left;
  width: 55%;
}
.Companyleft h4 {
  font-size: 1.2em;
}

.Companyleft p {
  font-size: 14px;
  padding-top: 2%;
  line-height: 28px;
  font-weight: 550;
}
.Companyright {
  padding-top: 4%;
  text-align: left;
  margin-left: auto;
  width: 50%;
  padding-left: 27%;
  padding-right: 5%;
}
.Companyright h1 {
  font-size: 2em;
  font-weight: 600;
  padding-bottom: 3%;
}
.Companyright > p {
  margin-top: 26px;
  margin-left: 3px;
}
.Companyright > p > a {
  font-size: 1em;
  color: #fff;
}

.Companyright > p > a:hover {
  text-decoration: none;
  color: #0a5de9;
  cursor: pointer;
}

.CompanyBtn1 {
  margin-top: 5%;
  border: 1px solid;
  background-color: transparent;
  border-color: white !important;
  text-align: center;
  padding: 1%;
  padding-left: 4%;
  padding-right: 4%;
  color: white;
}

.CompanyBtn1:hover {
  transition: 1s;
  background-color: white;
  color: black;
}

.Companymid {
  margin-left: 10%;
  margin-right: 10%;
  display: block;
}

.Companymid1 {
  text-align: center;
}
.Companymid1 h6 {
  padding-top: 1%;
  font-size: 1em;
  line-height: 25px;
  color: #808080;
}
.Companymid2 {
  text-align: center;
  display: flex;
}
.Image {
  margin-right: 50px;
}
.Companymid2 h6 {
  font-size: 14px;
  line-height: 25px;
  padding-left: 5%;
}

#image {
  max-width: 100%;
  margin-bottom: 10%;
}

.BackgroundLogo {
  width: 100%;
  height: 80vh;
  background-image: url("../../images/media_library/Group-972-1-min.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 10%;
}

.firstBox {
  border: 1 px solid;
  padding-bottom: 8%;
}

.CompanyBox {
  border: 2px solid transparent;
  border-radius: 20px;
  background-color: rgb(41, 41, 41);
  width: 650px;
  height: 245px;
  margin-left: 29%;
  padding-bottom: 2%;
}

.CompanyBox img {
  max-width: 5%;
  padding-top: 2%;
  padding-bottom: 1%;
}
.CompanyBox .Data {
  text-align: center;
  padding: 20px;
}
.Data h2 {
  font-size: 1.7em;
  font-weight: bold;
  margin-top: 2%;
  margin-bottom: 2%;
}

.Data h4 {
  font-size: 1.1em;
  padding-top: 1%;
}

.CompanyLast {
  margin-left: 10%;
  margin-right: 3%;
  display: inline-flex;
}
.CompanyLast img {
  width: 500px;
  padding-top: 20%;
}
.Process {
  padding-left: 10%;
}

.Companymid1 h6 {
  font-size: 1em;
  margin-bottom: 10%;
}

.Process h6 {
  font-size: 1em;
  width: 75%;
  color: #989898;
  margin-bottom: 5%;
}
/* 
#Image h6 {
  font-size: 1.1em;
  color: #989898;
  margin-bottom: 5%;
  padding-right: 10%;
} */

.Companyulist {
  background-color: transparent;
  list-style-type: none;
  padding-bottom: 1.5%;
  font-size: 1.3em;
}
.Companyulist li:hover {
  cursor: pointer;
  transition: 1s;
}

.Companylist:before {
  background-color: #666666;
  width: 2px;
  content: "";
  position: absolute;
  top: 20px;
  bottom: -11px;
  left: 5px;
}

.Companylist:nth-child(8):before {
  background-color: transparent;
}

.Companylist {
  /* You need to turn on relative positioning so the line is placed relative to the item rather than absolutely on the page */
  position: relative;
  /* Use padding to space things out rather than margins as the line would get broken up otherwise */
  padding-top: 0;
  padding-bottom: 1em;
  padding-left: 40px;
}
.Companylist::after {
  content: "";
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%233366ff' cx='16' cy='16' r='12'%3E%3C/circle%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  left: 0;
  top: 10px;
  width: 12px;
  height: 12px;
}

.CompanyTeam {
  width: 100%;
  height: 100vh;
  background-color: #0b0b0b;
  padding-top: 10%;
}

.teamSlide {
  width: 60%;
  margin: auto;
  position: relative;
}

.sliderArrow {
  position: absolute;
  width: 50px;
  left: 85%;
  bottom: -50px;
  display: flex;
  justify-content: space-between;
}

.sliderArrows {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.teamSlider {
  width: 100%;
}

.partnerSlide {
  width: 40%;
  margin: 20px auto;
}

.partnerSlider {
  width: 100%;
}

.teamProfile {
  width: 100px;
  height: 100px;
  border: solid white;
}

.teamProfile > img {
  width: 70%;
  height: 70%;
}

.subText {
  font-size: 0.8em;
  color: #808080;
  margin-top: 10px;
  margin-left: 5%;
}

.Partners {
  width: 100%;
  height: 90vh;
  padding-top: 10%;
  position: relative;
  background-color: #1c1c1c;
}

.contactUs {
  width: 100%;
  height: 80vh;
  padding-top: 2%;
  padding-bottom: 2%;
  position: relative;
  background-color: #0b0b0b;
}

.contactUsContent {
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 4%;
  justify-content: space-between;
}

.contactUsInfo {
  width: 40%;
  height: 80%;
}

.contactUsInfos {
  width: 100%;
  display: grid;
  row-gap: 30px;
  grid-template-columns: auto auto;
}

.contactUsSubInfo {
  width: 80%;
}

.contactUsFormContainer {
  padding-top: 2%;
  width: 40%;
  height: 100%;
  position: relative;
}

.contactUsForm {
  width: 100%;
  display: grid;
  row-gap: 50px;
  grid-template-columns: auto auto;
}

.contactUsInput {
  background: transparent;
  width: 80%;
  border: none;
  outline: none;
  padding: 10px;
  border-bottom: 1px solid #808080;
}

.contactUsInput:focus {
  transition: 0.4s ease-in-out;
  border-bottom: 1px solid blue;
}

.contactHeader {
  font-size: 14px;
  color: #808080;
}

.contactText {
  font-size: 16px;
}

.contactUsFormBtn {
  width: 150px;
  border-radius: 5px;
  padding: 10px;
  border: none;
  background: #0a5de9;
  position: absolute;
  right: 10%;
  bottom: 12.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactUsMap {
  width: 50%;
  height: fit-content;
  margin-top: 20%;
  display: flex;
  padding-top: 10px;
}

.contactUsMapArrow {
  transform: rotate(-90deg);
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: -5px;
  animation: animate 1.5s infinite ease-in-out;
}

#contactNo {
  width: 200px;
}

#contactUsInput {
  width: 90%;
}

@media only screen and (max-width: 1200px) {
  .Companyleft {
    width: 60%;
  }
  .contactUsInfo {
    width: 55%;
    column-gap: 20px;
  }
}

@keyframes animate {
  0% {
    margin-top: -5px;
  }
  25% {
    margin-top: -3px;
  }
  50% {
    margin-top: -1px;
  }
  75% {
    margin-top: -3px;
  }
  100% {
    margin-top: -5px;
  }
}

.techLogo {
  width: 100%;
  height: 85%;
  object-fit: contain;
}

@media screen and (max-width: 1800px) {
  .CompanyHeading {
    padding-top: 4%;
    padding-left: 10%;
  }
  .Companyright {
    padding-top: 1%;
    width: 55%;
    margin-left: 50%;
    padding-right: 5%;
  }
  .CompanyBtn1 {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1300px) {
  .CompanyHeading {
    padding-top: 4%;
    padding-left: 6%;
  }
  .CompanyBtn1 {
    margin-top: 10px;
  }
  .Companyright {
    width: 45%;
    padding-left: 15%;
  }
  .Companyleft p {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 1100px) {
  .CompanyHeading {
    padding-left: 5%;
    padding-right: 5%;
  }
  .Companyleft h1 {
    margin-bottom: 15px;
  }
  .Companyleft {
    width: 100%;
    margin-bottom: 15%;
  }
  br {
    display: none;
  }
  .CompanyHeading {
    padding-top: 10%;
    height: auto;
  }
  .Companyright {
    text-align: center;
    margin: auto;
    width: 100%;
    padding-left: 0;
    padding-right: 5%;
  }
  .Companymid {
    margin-left: 5%;
    margin-right: 5%;
  }
  .Companymid2 {
    display: block;
    font-size: 0.9em;
    padding-left: 0%;
  }
  #image {
    margin-bottom: 3%;
  }
  .firstBox {
    padding-bottom: 15%;
  }
  .CompanyBox {
    width: 600px;
    margin-left: 17%;
  }
  .Data {
    padding: 10px;
  }
  .Data > img {
    scale: 2;
  }
  .Data h2 {
    font-size: 20px;
  }
  .Data h4 {
    font-size: 16px;
  }

  .CompanyLast {
    margin-top: -20%;
    display: block;
  }
  .partnerSlide {
    width: 100%;
    margin: auto;
  }

  .partnerSlider {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .teamSlide {
    width: 80%;
  }
}

@media only screen and (max-width: 900px) {
  .Companyright {
    text-align: center;
    margin-top: 80%;
    width: 100%;
    padding-left: 0;
    padding-right: 5%;
  }
}

@media screen and (max-width: 800px) {
  .CompanyHeading {
    padding-top: 10%;
    text-align: center;
    background: #131313;
  }
  .partnerText {
    margin-bottom: 0;
  }
  .CompanyBG {
    display: none;
  }
  .CompanyBox {
    width: 550px;
    margin-left: 14%;
    margin-top: 6%;
  }
  .Companymid3 .Companyright {
    display: flex;
    flex-direction: column;
  }
  .Companyright {
    display: flex;
    flex-direction: column;
  }
  .Companyright > h1 {
    margin-bottom: 20px;
    text-align: center;
  }
  .Companyright > p {
    margin-top: 20px;
    text-align: center;
  }
  .Companyright > p > a {
    font-weight: 500;
  }
  .CompanyTeam {
    width: 100%;
  }
  .Companymid {
    padding-top: 50px;
  }
  .Image {
    margin: auto;
    padding-left: 0;
    padding-top: 50px;
  }
  .Companyulist {
    padding: 0;
  }
  .Companylist {
    padding-top: 5px;
  }
  .Partners {
    height: 70vh;
  }
  .CompanyLast img {
    width: 50%;
    object-fit: cover;
    margin-left: 20%;
  }
  .Companymid1 {
    height: 150px;
  }
  .Process {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .Process h1 {
    width: fit-content;
    padding-left: 23%;
  }

  .Process h6 {
    margin-left: 10px;
    text-align: center;
  }
  .Companyulist {
    padding-left: 13%;
  }
  .partnerSlide {
    margin-top: 70px;
  }
  .cHeader {
    margin-left: 10%;
  }
  .cfHeader {
    margin-left: -5%;
  }
  #contactUsInput {
    width: 85%;
  }
}

@media screen and (max-width: 605px) {
  .CompanyHeading {
    padding-top: 10%;
  }
  .CompanyLast img {
    margin-top: -25%;
    margin-left: 20%;
    max-width: 65%;
  }
  .Process {
    font-size: 0.8em;
  }
  .Process ul {
    font-size: 1.2em;
  }
  .partnerText {
    margin-bottom: 80px;
  }
}
.partnerText {
  margin-bottom: 80px;
}
@media screen and (max-width: 800px) {
  .Process > h1 {
    margin: 30px 0px 20px 0px;
  }
  .Process > h6 {
    margin-bottom: 50px;
  }
  .contactUs {
    padding: 10px;
    height: auto;
  }
  .Companymid1 h1 {
    margin-bottom: 10px;
  }
  .contactUsContent {
    text-align: center;
    flex-direction: column;
    justify-content: space-evenly;
    height: auto;
  }

  .contactUsInfo {
    width: 85%;
    height: 80%;
  }

  .contactUsInfos {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .contactUsSubInfo {
    width: 110%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contactUsFormContainer {
    width: 100%;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .contactUsForm {
    width: 100%;
    display: grid;
    column-gap: 20px;
    grid-template-columns: auto auto;
  }

  #contactUsInput {
    width: 90%;
    margin-right: 75px;
  }

  .contactUsFormBtn {
    bottom: -23%;
    left: 37%;
  }

  .contactUsMap {
    display: none;
  }
}

@media screen and (max-width: 499px) {
  .contactUsForm {
    display: flex;
    flex-direction: column;
  }
  .contactUsInput {
    width: 95%;
  }
  #contactUsInput {
    margin-right: 100px;
  }
  .contactUsFormBtn {
    bottom: -20%;
    left: 30%;
  }
  .Companyulist {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media screen and (max-width: 684px) {
  .BackgroundLogo {
    height: 50vh;
    margin-bottom: 35%;
  }
  .Companyleft h4 {
    font-size: 1.2em;
  }
  .CompanyHeading {
    height: auto;
  }
  .Companyright {
    padding-top: 3%;
    text-align: left;
    width: 100%;
    padding-left: 6%;
    padding-right: 9%;
  }
  .CompanyBox {
    margin-top: -5%;
    margin-left: 7%;
    margin-right: 0%;
    width: 500px;
    height: 200px;
  }
  .CompanyLast {
    margin-left: 2%;
    margin-right: 12%;
  }

  .CompanyLast img {
    width: 50%;
    object-fit: cover;
    margin-left: 30%;
  }

  .Process h1 {
    width: fit-content;
    padding-left: 32%;
  }

  .Process h6 {
    margin-left: 13%;
  }
  .Companyulist {
    padding-left: 17%;
  }
}

@media screen and (max-width: 512px) {
  .CompanyBox h4 {
    font-size: 0.6em;
  }
  .CompanyBox h2 {
    font-size: 1em;
  }
  .Companyleft p {
    font-size: 0.9em;
  }
  .Companyleft {
    margin-top: 20%;
  }
  .Companyleft h4 {
    font-size: 0.8em;
  }
  .CompanyHeading {
    height: auto;
  }
  .Companyright {
    padding-top: 3%;
    text-align: left;
    width: 100%;
    padding-left: 10%;
    padding-right: 5%;
  }
  .CompanyLast {
    margin-right: 2%;
    margin-left: 0%;
  }
  .CompanyLast img {
    width: 50%;
    object-fit: cover;
    margin-left: 25%;
  }

  .Process h1 {
    width: fit-content;
    padding-left: 25%;
  }

  .Process h6 {
    margin-left: 10%;
  }
  .Companyulist {
    padding-left: 15%;
  }
  .CompanyBox {
    border-radius: 10px;
    margin-top: 5%;
    width: 90%;
    margin-left: 5%;
    height: 150px;
  }
  .teamSlide {
    width: 80%;
    margin: auto;
  }
  .teamSlider {
    width: 100%;
  }
  .partnerSlide {
    width: 100%;
    margin: auto;
  }
  .partnerSlider {
    width: 100%;
  }
  .partnerText {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 500px) {
  #contactUsInput {
    width: 95%;
  }
  .contactUsFormBtn {
    margin-bottom: 15px;
  }
}
