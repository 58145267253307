@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

body::-webkit-scrollbar,
body::-webkit-scrollbar-thumb {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  cursor: pointer;
  background: white;
}

.main-wrap {
  flex-direction: column;
}

/* .Main-footer {
  margin-top: 10%;
  margin-bottom: 0%;
} */

@media screen and (max-width: 450px) {
  .Main-footer {
    margin-top: 40%;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0); /* Start and end position */
  }
  50% {
    transform: translateY(-0.5rem); /* Mid-point position (upwards) */
  }
}

/* Adding the animation to the class 'animate-bounce' */
.animate-bounce {
  animation: bounce 1.5s ease-in-out infinite; /* Duration and easing */
}

.inpuText {
  text-align: center;
}

@media screen and (min-width: 1024px) {
  .inpuText {
    text-align: left;
  }
}
